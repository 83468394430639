import "./App.css";
import SplashPage from "./SplashPage";
import SplashPage2 from "./SplashPage2";

function App() {
  return (
    <div className="App">
      <SplashPage />
      {/* <SplashPage2 /> */}
    </div>
  );
}

export default App;
